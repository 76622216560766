<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">WhatsApp Logs</div>
        <div class="
        col-lg-6 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
            <div class="input-group input-group-sm custom-form-group mb-0 me-2"
                v-bind:style="[searchnamebtnactive ? { width: '328px' } : { width: '275px' }]">
                <input type="text" v-model="searchfullname" class="form-control text-capitalize"
                    placeholder="Search By First Or Last Name" aria-label="Search" aria-describedby="basic-addon1"
                    autocomplete="off" maxlength="30" v-on:keyup.enter="searchFnameVoter(searchfullname)" @keypress="isLetterWithSpace($event)" :disabled="hidesearchname"
                    style="padding: 6px 15px;" />
                <button type="button" class="header-btn-outer btn btn-primary" :disabled="searchfullname.length == ''"
                    @click="searchFnameVoter(searchfullname)" :style="{ width: '50px' }">
                    <span v-if="!searchnameloader"><i class="pi pi-search" aria-hidden="true"></i></span>
                    <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                        v-if="searchnameloader">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </button>
                <button type="button" class="header-btn-outer btn btn-primary ms-1" @click="searchNameVoterStop()"
                    :style="{ width: '50px' }" v-show="searchnamebtnactive"><i class="pi pi-times"
                        aria-hidden="true"></i>
                </button>
            </div>
            <div class="input-group input-group-sm custom-form-group mb-0"
                v-bind:style="[searchmobbtnactive ? { width: '275px' } : { width: '220px' }]">
                <input type="text" v-model="searchmobile" class="form-control" id="searchmobInput"
                    placeholder="Search By Mobile No." :disabled="hidesearchmob" aria-label="Search"
                    aria-describedby="basic-addon1" autocomplete="off" maxlength="10" @paste="onPasteMobile"
                    @keypress="mobileSearchFormat" v-on:keyup.enter="searchMobVoter(searchmobile)" style="padding: 6px 15px;" />
                <button type="button" class="header-btn-outer btn btn-primary" :disabled="searchmobile.length <= 3"
                    @click="searchMobVoter(searchmobile)" :style="{ width: '50px' }">
                    <span v-if="!searchmobloader"><i class="pi pi-search" aria-hidden="true"></i></span>
                    <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                        v-if="searchmobloader">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </button>
                <button type="button" class="header-btn-outer btn btn-primary ms-1" @click="searchMobVoterStop()"
                    :style="{ width: '50px' }" v-show="searchmobbtnactive"><i class="pi pi-times"
                        aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
        <DataTable :value="whatsappLogList" :scrollable="true" scrollHeight="flex" columnResizeMode="fit"
            :paginator="true" :lazy="true" :rowHover="true" :totalRecords="totalRecords" :rows="30" dataKey="lr1"
            @page="changePage($event)"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading">
            <template v-if="!loading" #empty>No records found.</template>
            <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
            <Column field="Name" header="Name" headerStyle="width: 20%" bodyStyle="width: 20%">
                <template #body="{ data }">
                    <div class="text-capitalize">
                        <div class="fw-bold">{{ data.lr14 ? data.lr14 : "N/A" }}</div>
                        <div>{{ data.lr15 ? data.lr15 : "N/A" }}</div>
                    </div>
                </template>
            </Column>
            <Column field="datetime" header="Date Time" headerStyle="width: 17%" bodyStyle="width: 17%">
                <template #body="{ data }">
                    <div>{{ format_timestamp(data.lr2) }}</div>
                </template>
            </Column>
            <Column field="message" header="WhatsApp Message" class="justify-content-between" headerStyle="width: 50%"
                bodyStyle="width: 50%">
                <template #body="{ data }">
                    <div>
                        <div style="max-width: 495px; word-wrap: break-word;">{{ decodeHtml(data.lr4) }}</div>
                        <div v-if="data.lr20 != null && data.lr20 != ''">
                            <img @click="whatsappimagepreview(data)" :src="data.lr20"
                                style="vertical-align: middle; height:50px"
                                class="whatsapp-Sent-image cursor-pointer whatsapp-imger-overlay" />
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="action" header="Action" class="justify-content-center" headerStyle="width: 13%"
                bodyStyle="width: 13%">
                <template #body="{ data }">
                    <button type="button" class="btn custom-view-detail-btn" @click="whatsappHistoryModalOpen(data)">
                        History
                    </button>
                </template>
            </Column>
        </DataTable>
    </div>
    <!-- whatsApp History modal start here -->
    <div class="modal-mask" v-if="whatsappmodalsflag">
        <div
            class="modal-dialog modal-lg modal-dialog-end mt-0 mb-0 me-0 custom-modal-outer modal-dialog-scrollable vh-100">
            <div class="modal-content vh-100">
                <div class="modal-header whatsapp-header">
                    <h5 class="modal-title" id="staticBackdropLabel">
                        <div class="d-flex align-items-bottom">
                            <div class="flex-shrink-0">
                                <img v-if="this.whatsappprofile != null && this.whatsappprofile != ''"
                                    :src="this.whatsappprofile" class="profile-header-avatar rounded-circle" alt=""
                                    width="35" height="35" />
                                <img v-else src="/assets/images/profile.svg"
                                    class="profile-header-avatar rounded-circle" alt="" width="35" height="35" />
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <div class="whatsapp-header-name text-capitalize">{{ this.whatsappname ?
                    this.whatsappname :
                    "N/A" }}</div>
                                <div class="whatsapp-header-number">{{ this.whatsappnumber }}</div>
                            </div>
                        </div>
                    </h5>
                    <div class="d-flex justify-content-end align-items-center">
                        <InputSwitch v-model="ApiLoadingSwitch" class="me-3" @change="switchLoadingStatus()" />
                        <button type="button" title="Refresh" class="btn custom-outline-refresh-btn me-3"
                            @click="getwhatsappchatlogs()">
                            <i class="pi pi-refresh refresh-btn-color"></i>
                        </button>
                        <button type="button" class="btn-close" @click="whatsappHistoryModalClose()"></button>
                    </div>
                </div>
                <div class="modal-body p-0">
                    <div class="chatbox-right-outer">
                        <div class="chatbox-inner-content">
                            <div class="widget-chat p-dialog-content">
                                <div id="chatcontainer" class="conversation-container" v-if="!showloadermodal">
                                    <div v-for="msg in whatsAppChatHistory" :key="msg">
                                        <div class="message received" v-if="msg.lr3 == 1">
                                            <div v-if="msg.lr20" class="mb-1">
                                                <img @click="whatsappimagepreview(msg)" :src="msg.lr20"
                                                    style="vertical-align: middle; width:300px"
                                                    class="whatsapp-Sent-image p-mt-1 cursor-pointer whatsapp-imger-overlay" />
                                            </div>
                                            <div v-if="msg.lr18 != 4">
                                                {{ decodeHtml(msg.lr4) }}
                                            </div>
                                            <div v-if="msg.lr18 == 4">
                                                <button type="button" class="btn btn-primary btn-sm" disabled
                                                    v-if="msg.lr11 == 0">{{ decodeHtml(msg.lr4) }}</button>
                                                <button type="button" class="btn btn-secondary btn-sm" disabled
                                                    v-if="msg.lr11 == 1">{{ decodeHtml(msg.lr4) }}</button>
                                            </div>
                                            <a class="whatsapp-image-cursor" v-if="msg.lr6"><img :src="msg.lr6"
                                                    style="vertical-align: middle"
                                                    class="whatsapp-received-image p-mt-1" /></a>
                                            <!-- <a class="whatsapp-image-cursor" v-if="msg.ct9"><img :src="msg.ct9"
                                                                style="vertical-align: middle"
                                                                class="whatsapp-received-image p-mt-1" /></a>
                                                        <a class="whatsapp-image-cursor" v-if="msg.ct10"><img
                                                                :src="msg.ct10" style="vertical-align: middle"
                                                                class="whatsapp-received-image p-mt-1" /></a> -->
                                            <span class="metadata"><span class="time">{{ format_timestamp(msg.lr2)
                                                    }}</span></span>
                                        </div>

                                        <div class="message sent" v-if="msg.lr3 == 2">
                                            <div v-if="msg.lr20" class="mb-1">
                                                <img @click="whatsappimagepreview(msg)" :src="msg.lr20"
                                                    style="vertical-align: middle; width:300px"
                                                    class="whatsapp-Sent-image p-mt-1 cursor-pointer whatsapp-imger-overlay" />
                                            </div>
                                            <div v-if="msg.lr18 != 4">
                                                {{ decodeHtml(msg.lr4) }}
                                            </div>
                                            <div v-if="msg.lr18 == 4">
                                                <button type="button" class="btn btn-primary btn-sm" disabled
                                                    v-if="msg.lr11 == 0">{{ decodeHtml(msg.lr4) }}</button>
                                                <button type="button" class="btn btn-secondary btn-sm" disabled
                                                    v-if="msg.lr11 == 1">{{ decodeHtml(msg.lr4) }}</button>
                                            </div>
                                            <a class="whatsapp-image-cursor" v-if="msg.lr6"><img :src="msg.lr6"
                                                    style="vertical-align: middle"
                                                    class="whatsapp-Sent-image p-mt-1" /></a>
                                            <!-- <a class="whatsapp-image-cursor" v-if="msg.ct9"><img :src="msg.ct9"
                                                                style="vertical-align: middle"
                                                                class="whatsapp-received-image p-mt-1" /></a>
                                                        <a class="whatsapp-image-cursor" v-if="msg.ct10"><img
                                                                :src="msg.ct10" style="vertical-align: middle"
                                                                class="whatsapp-received-image p-mt-1" /></a> -->
                                            <span class="metadata"><span class="time">{{ format_timestamp(msg.lr2)
                                                    }}</span></span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showloadermodal">
                                    <div class="custom-modal-spinner-loader">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer p-0">
                    <div class="whatsapp-textbtn-outer">
                        <div class="custom-chat-picture text-success" v-if="uploadfilename">
                            File attatched
                        </div>
                        <div class="conversation-compose">
                            <textarea class="form-control input-msg custom-chat-group-textarea"
                                :class="this.disabledWhatsAppInputPermission == 0 ? 'cursor-not-allowed' : ''"
                                :disabled="this.disabledWhatsAppInputPermission == 0" :autoResize="true"
                                v-model="chatInput" rows="2" placeholder="Type Message here..." />
                            <div class="send-btn-outer">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 ms-2">
                                        <label class="fileUpload btn-default upload-document"
                                            :class="this.disabledWhatsAppInputPermission == 0 ? 'cursor-not-allowed' : 'cursor-pointer'">
                                            <i class="pi pi-paperclip" style="font-size: 1rem" v-bind:style="[this.disabledWhatsAppInputPermission == 0 ? { color: '#e9ecef' } : '']"></i>

                                            <input type="file" hidden="" name="uploadfile" ref="uploadfile" multiple
                                                v-on:change="validateAttachment"
                                                :disabled="this.disabledWhatsAppInputPermission == 0" />
                                        </label>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <button class="send" @click="sendMessage()" :disabled="this.disabledWhatsAppInputPermission == 0 || showloaderbtn">
                                            <i class="pi pi-send" v-if="!showloaderbtn" style="font-size: 1rem"></i>
                                            <div class="
                                            spinner-border
                                            text-light
                                            custom-spinner-loader-btn
                                          " role="status" v-if="showloaderbtn"></div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- whatsApp History modal end here -->
    <div v-if="ImagePreviewDialog" class="modal-mask">
        <div class="modal-dialog modal-md modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Image Preview</h4>
                    <button class="btn-close" @click="whatsappimagepreviewClose"></button>
                </div>
                <div class="modal-body p-0">
                    <img :src="previewimg" style="vertical-align: middle; width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import moment from "moment";

export default {
    data() {
        return {
            whatsappLogList: null,
            totalRecords: 0,
            page_no: 0,
            loading: false,
            whatsappmodalsflag: false,
            showloadermodal: false,
            rowId: "",
            whatsAppChatHistory: [],
            whatsappusernumber: "",
            whatsappname: "",
            whatsappnumber: "",
            ApiLoadingSwitch: false,
            showloaderbtn: false,
            uploadfile: null,
            file1: null,
            uploadfilename: null,
            chatInput: "",
            ImagePreviewDialog: false,
            disabledWhatsAppInputPermission: '',
            whatsappprofile: '',
            searchmobile: '',
            searchmobloader: false,
            searchfullname: '',
            searchnameloader: false,
            searchmobbtnactive: false,
            searchnamebtnactive: false,
            hidesearchname: false,
            hidesearchmob: false,
        };
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },

    mounted() {
        this.getwhatsapplogs();
    },
    beforeUnmount() {
        clearInterval(this.timer3);
    },
    methods: {
        getwhatsapplogs(item) {
            this.loading = true;
            this.ApiService.getwhatsapplogs(item).then((data) => {
                if (data.success === true) {
                    this.whatsappLogList = data.records;
                    this.totalRecords = data.count;
                    this.loading = false;
                    this.searchnameloader = false;
                    this.searchmobloader = false;
                    this.hidesearchname = false;
                    this.hidesearchmob = false;
                } else {
                    this.loading = false;
                    this.whatsappLogList = null;
                    this.totalRecords = 0;
                    this.searchnameloader = false;
                    this.searchmobloader = false;
                    this.hidesearchname = false;
                    this.hidesearchmob = false;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getwhatsapplogs({ page_no: this.page_no });
        },
        whatsappHistoryModalOpen(e) {
            this.rowId = e.lr13;
            this.whatsappprofile = e.lr31;
            this.whatsappname = e.lr14;
            this.whatsappnumber = e.lr15;
            this.disabledWhatsAppInputPermission = e.whatsapp;
            this.whatsappmodalsflag = true;
            this.getwhatsappchatlogs();
        },
        getwhatsappchatlogs() {
            this.showloadermodal = true;
            this.chatInput = "";
            this.file1 = "";
            this.uploadfilename = "";
            this.ApiService.getwhatsappchatlogs({ lr13: this.rowId }).then((data) => {
                if (data.success == true) {
                    this.showloadermodal = false;
                    this.whatsAppChatHistory = data.records;
                }
            });
            this.scrollmessage();
        },
        whatsappHistoryModalClose() {
            this.whatsappmodalsflag = false;
            clearInterval(this.timer3);
            this.ApiLoadingSwitch = false;
        },
        switchLoadingStatus() {
            if (this.ApiLoadingSwitch == true) {
                this.timer3 = window.setInterval(() => {
                    this.getwhatsappchatlogstimer();
                }, 2000);
            } else {
                clearInterval(this.timer3);
            }
        },
        getwhatsappchatlogstimer() {
            this.ApiService.getwhatsappchatlogs({ lr13: this.rowId }).then((data) => {
                if (data.success == true) {
                    this.whatsAppChatHistory = data.records;
                }
            });
        },
        sendMessage() {
            const formData = new FormData();
            formData.append("mobile_number", this.whatsappnumber);
            formData.append("voter_fk", this.rowId);
            formData.append("voter_fk_value", this.whatsappname);
            if (this.chatInput) {
                formData.append("message", this.chatInput);
            }
            if (this.file1) {
                formData.append("media", this.file1);
            }
            if (this.disabledWhatsAppInputPermission == 0) {
                formData.append("session_message", 0);
            } else {
                formData.append("session_message", 1);
            }
            this.showloaderbtn = true;
            this.ApiService.sendwhatsappmessage(formData).then((data) => {
                if (data.success == true) {
                    this.showloaderbtn = false;
                    this.chatInput = "";
                    this.file1 = "";
                    this.uploadfilename = "";
                    this.getwhatsappchatlogs();
                    this.scrollmessage();
                } else {
                    this.showloaderbtn = false;
                    this.chatInput = "";
                    this.file1 = "";
                    this.uploadfilename = "";
                    this.getwhatsappchatlogs();
                }
            }
            );
        },
        validateAttachment(e) {
            var fileData = e.target.files[0];
            this.uploadfilename = fileData.name;
            if (this.$refs.uploadfile.files.length > 3) {
                this.$toast.open({
                    message: "Maximum 3 files are allowed",
                    type: "success",
                    duration: 3000,
                    position: "top-right",
                });
            } else {
                let len = this.$refs.uploadfile.files.length;
                if (len === 1) {
                    this.file1 = this.$refs.uploadfile.files[0];
                } else if (len === 2) {
                    this.file1 = this.$refs.uploadfile.files[0];
                    this.file2 = this.$refs.uploadfile.files[1];
                } else if (len === 3) {
                    this.file1 = this.$refs.uploadfile.files[0];
                    this.file2 = this.$refs.uploadfile.files[1];
                    this.file3 = this.$refs.uploadfile.files[2];
                }
            }
        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return 'N/A';
            }
        },
        scrollmessage() {
            setTimeout(() => {
                var messageBody = document.querySelector('#chatcontainer');
                // console.log(messageBody);
                if (messageBody != null) {
                    messageBody.scrollTop = messageBody.scrollHeight;
                }
            }, 1000);
        },
        whatsappimagepreview(data) {
            this.previewimg = data.lr20;
            this.ImagePreviewDialog = true;
        },
        whatsappimagepreviewClose() {
            this.ImagePreviewDialog = false;
        },
        decodeHtml(html) {
            return JSON.parse('"' + html + '"');
        },
        // Search Voter Header Input function start here
        searchMobVoter(
            searchmobile
        ) {
            this.hidesearchmob = true;
            this.searchmobloader = true;
            this.lr15 = searchmobile;
            this.getwhatsapplogs({
                page_no: this.page_no,
                lr15: this.lr15,
            });
            this.searchmobbtnactive = true;
        },
        searchMobVoterStop() {
            this.searchmobbtnactive = false;
            this.searchmobile = "";
            this.getwhatsapplogs({
                page_no: this.page_no,
            });
            this.searchmobloader = false;
        },
        searchFnameVoter(
            searchfullname
        ) {
            this.hidesearchname = true;
            this.searchnameloader = true;
            this.lr14 = searchfullname;
            this.getwhatsapplogs({
                page_no: this.page_no,
                lr14: this.lr14,
            });
            this.searchnamebtnactive = true;
        },
        searchNameVoterStop() {
            this.searchnamebtnactive = false;
            this.searchfullname = "";
            this.getwhatsapplogs({
                page_no: this.page_no,
            });
            this.searchnameloader = false;
        },
        // Search Voter Header Input function end here
        isLetterWithSpace(e) {
            let charspace = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z\s]+$/.test(charspace)) return true;
            else e.preventDefault();
        },
        onPasteMobile(event) {
            let mobvalue = event.clipboardData.getData('text');
            if (/^[0-9]+$/.test(mobvalue)) return true;
            else event.preventDefault();
        },
        mobileSearchFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var mobileInput = document.getElementById("searchmobInput");
            if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
    },
};
</script>
<style scoped>
.chatbox-left-outer {
    max-width: 356px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e9e9e9;
    border-radius: 4px 0px 4px 4px;
    opacity: 1;
    height: 631px;
}

.chatbox-left-outer .chatbox-inner-content {
    padding: 15px 10px 15px 20px;
    border-bottom: 1px solid #e9e9e9;
    opacity: 1;
}

.chatbox-left-outer .chatbox-inner-content:hover {
    /* border: 1px solid var(--unnamed-color-e9e9e9); */
    background: #f7f9fd 0% 0% no-repeat padding-box;
    /* border: 1px solid #E9E9E9; */
    opacity: 1;
}

.chatbox-left-outer .chatbox-inner-content .applicants-header-name {
    text-align: left;
    letter-spacing: 0px;
    color: #2a2a2a;
    opacity: 1;
    font-size: 13px;
    font-family: "Roboto-Medium";
}

.chatbox-left-outer .chatbox-inner-content .applicants-header-reader {
    text-align: left;
    letter-spacing: 0px;
    color: #4a4a4a;
    opacity: 1;
    font-size: 12px;
    font-family: "AcuminPro-Regular";
    cursor: pointer;
}

.chatbox-right-outer {
    max-width: 752px;
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    /* height: 550px; */
    padding-left: 0px;
    border-radius: 0.3rem;
    height: 100%;
}

.chatbox-left-outer .chatbox-inner-content .applicants-name {
    text-align: left;
    letter-spacing: 0px;
    color: #2a2a2a;
    opacity: 1;
    font-size: 14px;
    font-family: "OpenSans-SemiBold";
    margin-left: 12px;
}

.chatbox-left-outer .chatbox-inner-content .applicants-timer {
    text-align: left;
    letter-spacing: 0px;
    color: #3a3a3a;
    opacity: 1;
    font-size: 12px;
    font-family: "AcuminPro-Regular";
}

.chatbox-left-outer .chatbox-inner-content .applicants-content {
    text-align: left;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-top: 10px;
    font-size: 12px;
    font-family: "AcuminPro-Regular";
}



.chatbox-right-outer .chatbox-inner-content {
    /* padding: 20px 0px 0px; */
    opacity: 1;
    height: 100%;
}

.p-dialog-header {
    height: 55px;
    background: #005e54;
    color: #fff;
    padding: 0 8px;
    font-size: 24px;
    position: relative;
    z-index: 1;
}

.p-dialog-header .avatar {
    margin: 0 0 0 5px;
    width: 36px;
    height: 36px;
}

.p-dialog-header .avatar img {
    border-radius: 50%;
    display: block;
    width: 100%;
}

.p-dialog-header .name {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0.3px;
    margin: 0 0 0 8px;
    overflow: hidden;
    text-transform: capitalize;
}

.p-dialog-header .status {
    display: block;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
}

.p-dialog-header .p-dialog-header-icon,
.p-dialog-header .p-dialog-header-icon:hover {
    color: #ffffff;
}

.p-dialog-content {
    position: relative;
    background: #efe7dd url("/assets/images/whatsapp-bg-img.jpg") repeat;
    z-index: 0;
    padding: 0;
    overflow: auto;
    height: 100%;
}

.conversation-container {
    height: calc(100% - 5px);
    overflow-x: hidden;
    padding: 0 16px;
    margin-bottom: 5px;
}

.message {
    color: #000;
    clear: both;
    line-height: 18px;
    font-size: 15px;
    padding: 8px;
    position: relative;
    margin: 8px 0;
    max-width: 50%;
    word-wrap: break-word;
}

.message.sent {
    background: #d2f1e1;
    border-radius: 5px 0px 5px 5px;
    float: right;
    text-align: left;
    letter-spacing: 0px;
    color: #4a4a4a;
    font-size: 13px;
    opacity: 1;
    font-family: "AcuminPro-Regular";
}

.message.received {
    background: #cfddf5;
    border-radius: 0px 5px 5px 5px;
    float: left;
    letter-spacing: 0px;
    color: #4a4a4a;
    opacity: 1;
    font-family: "AcuminPro-Regular";
    font-size: 13px;
}

.metadata {
    display: inline-block;
    float: right;
    padding: 0 0 0 7px;
    position: relative;
    bottom: -4px;
}

.whatsapp-header {
    background: #005e54 0% 0% no-repeat padding-box;
}

.whatsapp-header .modal-title {
    color: #ffffff;
}

.whatsapp-header .btn-close {
    background-color: #ffffff;
}

.whatsapp-header-name {
    font-size: 17px;
}

.whatsapp-header-number {
    font-size: 12px;
}

.conversation-container::-webkit-scrollbar,
.p-dialog-content::-webkit-scrollbar,
.input-msg::-webkit-scrollbar {
    transition: all 0.5s !important;
    width: 3px !important;
    height: 3px !important;
    z-index: 10 !important;
}

.conversation-container::-webkit-scrollbar-thumb,
.p-dialog-content::-webkit-scrollbar-thumb,
.input-msg::-webkit-scrollbar-thumb {
    background: #7f7777 !important;
}

.metadata .time {
    color: rgba(0, 0, 0, 0.45);
    font-size: 11px;
    display: inline-block;
}

.message.sent:after {
    border-width: 0px 0 10px 10px;
    border-color: transparent transparent transparent #d2f1e1;
    top: 0;
    right: -10px;
}

.message.received:after {
    border-width: 0px 10px 10px 0;
    border-color: transparent #cfddf5 transparent transparent;
    top: 0;
    left: -10px;
}

.message:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
}

.whatsapp-received-image {
    max-width: 150px;
    width: 100%;
    display: block;
    cursor: pointer;
}

.whatsapp-image-cursor {
    opacity: 1;
    transition: 0.5s ease;
    backface-visibility: hidden;
}

.whatsapp-image-cursor:hover {
    opacity: 0.5;
    display: block;
}

.conversation-compose {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow: hidden;
    height: 50px;
    width: 100%;
    z-index: 2;
}

.conversation-compose .input-msg {
    border: 0;
    flex: 1 1 auto;
    font-size: 16px;
    margin: 0;
    outline: none;
    min-width: 50px;
    height: 50px !important;
    overflow: auto !important;
    border-radius: 5px 0 0 5px;
}

.conversation-compose .input-msg,
.conversation-compose .attachment {
    background: #fff;
    height: 100%;
}
.conversation-compose .input-msg:disabled,
.conversation-compose .send:disabled {
    background-color: #e9ecef;
}

.conversation-compose .send-btn-outer {
    background: transparent;
    border: 0;
    flex: 0 0 auto;
    margin-left: 8px;
    padding: 0;
    position: relative;
    outline: none;
}

.send-btn-outer .pi.pi-send {
    font-size: 1.5rem;
    transform: rotate(45deg);
}

.conversation-compose .send {
    background: #008a7c;
    border-radius: 50%;
    color: #fff;
    position: relative;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
    margin-bottom: 2px;
    border: 0;
}

/* .conversation-compose .send:hover {
    border-color: #86b7fe;
} */

.conversation-compose .input-msg:hover {
    background-image: none;
}

.conversation-compose .input-msg:focus {
    background-color: #ffffff;
}

.whatsapp-textbtn-outer {
    padding-left: 8px;
    padding-top: 10px;
    padding-right: 8px;
    background-color: #d3d3d3;
    padding-bottom: 9px;
    border-radius: 0 0 0.3rem 0.3rem;
    width: 100%;
    margin: 0;
}

.conversation-compose .attachment {
    flex: 0 0 auto;
    border-radius: 0 0 5px 0;
    text-align: center;
    position: relative;
}

.conversation-compose .attachment:after {
    border-width: 0px 0 10px 10px;
    border-color: transparent transparent transparent #fff;
    border-style: solid;
    position: absolute;
    width: 0;
    height: 0;
    content: "";
    top: 0;
    right: -10px;
}

.conversation-compose .attachment .p-fileupload.p-fileupload-basic {
    transform: translate(-50%, -50%);
    position: relative;
    top: 50%;
    left: 50%;
}

.conversation-compose .attachment .p-fileupload-basic .p-fileupload-choose {
    display: block;
    background-color: transparent;
}

.conversation-compose .attachment .pi {
    display: block;
    color: #7d8488;
    font-size: 24px;
    margin-right: 0px;
}

.conversation-compose .attachment .p-button-label {
    display: none;
}

.conversation-compose .attachment .p-ink {
    display: none;
}

.whatsapp-popup-loader .pi {
    font-size: 2rem !important;
}

.form-control.input-msg.custom-chat-group-textarea {
    text-align: left;
    letter-spacing: 0px;
    color: #4a4a4a;
    opacity: 1;
    font-size: 14px;
    font-family: "AcuminPro-Regular";
    padding: 10px 10px;
    resize: none;
}

.form-control.input-msg.custom-chat-group-textarea::placeholder {
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    font-size: 14px;
    font-family: "AcuminPro-Regular";
    text-align: start;
}

.form-control.input-msg.custom-chat-group-textarea:focus {
    border: 1px solid #86b7fe;
    box-shadow: none;
}

.whatsapp-imger-overlay:hover {
    opacity: 0.7;
}

.custom-outline-refresh-btn {
    padding: 6.5px;
    line-height: 10px;
    opacity: .5;
    background-color: #ffffff;
    border-radius: 0.25rem;
}

.custom-outline-refresh-btn:hover {
    opacity: .75;
}

.custom-outline-refresh-btn .refresh-btn-color {
    color: #000000;
    font-size: 13px;
}

@media only screen and (min-width: 1024px) {
    .chatbox-right-outer {
        max-width: 100% !important;
    }
}
</style>